class FFInput extends FFComponent {
    constructor(elem) {
        super(elem);
        this._hasDatepicker = this.elem.classList.contains('datepicker');

        // IE10 Compatibility
        if (this.elem.dataset !== undefined) {
            this._ajaxAutocomplete = this.elem.dataset.ajaxAutocomplete;
        }
        else {
            this._ajaxAutocomplete = this.elem.getAttribute('data-ajax-autocomplete');
        }

        if (this._ajaxAutocomplete && this._ajaxAutocomplete.indexOf('://') === -1 && this._ajaxAutocomplete.indexOf('//') !== 0) {
            this._ajaxAutocomplete = FF.makeCompanyUrl(this._ajaxAutocomplete);
        }
        this.init();
    }

    _initDOM() {
        this.elem.addEventListener('mousedown', function () {
            this.classList.add('active');
        });
        this.elem.addEventListener('mouseup', function () {
            this.classList.remove('active');
        });
        this.elem.addEventListener('mouseleave', function () {
            this.classList.remove('active');
        });

        for (let input of this.elem.querySelectorAll('input')) {
            input.addEventListener('blur', function () {
                this.classList.add('touched');
            });
            input.addEventListener('input', function () {
                this.classList.add('touched');
            });
            input.addEventListener('focus', () => this.elem.classList.add('focus'));
            input.addEventListener('blur', () => this.elem.classList.remove('focus'));

            if (this._hasDatepicker && $.fn.pikaday) {
                let conf = window.moment ? {
                    format: moment.localeData()._longDateFormat.L,
                    i18n: {
                        months: moment.localeData()._months,
                        weekdays: moment.localeData()._weekdays,
                        weekdaysShort: moment.localeData()._weekdaysShort
                    }
                } : {};
                if (input.dataset.datepickerFutureOnly) {
                    conf.minDate = new Date();
                }
                $(input).pikaday(conf);
            }

            if (this._ajaxAutocomplete && $.fn.autocomplete) {
                $(input).autocomplete({
                    serviceUrl: this._ajaxAutocomplete,
                    onSelect: function (suggestion) {
                        this.value = suggestion.data;
                    }
                })
            }
        }

        let okControl = this.elem.querySelector('.control.ok');

        if (okControl) {
            okControl.addEventListener('click', e => {
                e.preventDefault();
                for (let input of this.elem.querySelectorAll('input')) {
                    input.blur();
                }
            });
        }

        let clrControl = this.elem.querySelector('.control.clr');

        if (clrControl) {
            clrControl.addEventListener('click', e => {
                e.preventDefault();
                let i = 0;
                for (let input of this.elem.querySelectorAll('input')) {
                    input.value = '';
                    if (i++ === 0) {
                        input.focus();
                    }
                }
            });
        }
    }
}

$(function () {
    $('.ff-input').each(function () {
        $(this).data('component', new FFInput(this));
    });
});
