'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var FFInput = function (_FFComponent) {
    _inherits(FFInput, _FFComponent);

    function FFInput(elem) {
        _classCallCheck(this, FFInput);

        var _this = _possibleConstructorReturn(this, (FFInput.__proto__ || Object.getPrototypeOf(FFInput)).call(this, elem));

        _this._hasDatepicker = _this.elem.classList.contains('datepicker');

        // IE10 Compatibility
        if (_this.elem.dataset !== undefined) {
            _this._ajaxAutocomplete = _this.elem.dataset.ajaxAutocomplete;
        } else {
            _this._ajaxAutocomplete = _this.elem.getAttribute('data-ajax-autocomplete');
        }

        if (_this._ajaxAutocomplete && _this._ajaxAutocomplete.indexOf('://') === -1 && _this._ajaxAutocomplete.indexOf('//') !== 0) {
            _this._ajaxAutocomplete = FF.makeCompanyUrl(_this._ajaxAutocomplete);
        }
        _this.init();
        return _this;
    }

    _createClass(FFInput, [{
        key: '_initDOM',
        value: function _initDOM() {
            var _this2 = this;

            this.elem.addEventListener('mousedown', function () {
                this.classList.add('active');
            });
            this.elem.addEventListener('mouseup', function () {
                this.classList.remove('active');
            });
            this.elem.addEventListener('mouseleave', function () {
                this.classList.remove('active');
            });

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.elem.querySelectorAll('input')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var input = _step.value;

                    input.addEventListener('blur', function () {
                        this.classList.add('touched');
                    });
                    input.addEventListener('input', function () {
                        this.classList.add('touched');
                    });
                    input.addEventListener('focus', function () {
                        return _this2.elem.classList.add('focus');
                    });
                    input.addEventListener('blur', function () {
                        return _this2.elem.classList.remove('focus');
                    });

                    if (this._hasDatepicker && $.fn.pikaday) {
                        var conf = window.moment ? {
                            format: moment.localeData()._longDateFormat.L,
                            i18n: {
                                months: moment.localeData()._months,
                                weekdays: moment.localeData()._weekdays,
                                weekdaysShort: moment.localeData()._weekdaysShort
                            }
                        } : {};
                        if (input.dataset.datepickerFutureOnly) {
                            conf.minDate = new Date();
                        }
                        $(input).pikaday(conf);
                    }

                    if (this._ajaxAutocomplete && $.fn.autocomplete) {
                        $(input).autocomplete({
                            serviceUrl: this._ajaxAutocomplete,
                            onSelect: function onSelect(suggestion) {
                                this.value = suggestion.data;
                            }
                        });
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var okControl = this.elem.querySelector('.control.ok');

            if (okControl) {
                okControl.addEventListener('click', function (e) {
                    e.preventDefault();
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = _this2.elem.querySelectorAll('input')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var _input = _step2.value;

                            _input.blur();
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }
                });
            }

            var clrControl = this.elem.querySelector('.control.clr');

            if (clrControl) {
                clrControl.addEventListener('click', function (e) {
                    e.preventDefault();
                    var i = 0;
                    var _iteratorNormalCompletion3 = true;
                    var _didIteratorError3 = false;
                    var _iteratorError3 = undefined;

                    try {
                        for (var _iterator3 = _this2.elem.querySelectorAll('input')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                            var _input2 = _step3.value;

                            _input2.value = '';
                            if (i++ === 0) {
                                _input2.focus();
                            }
                        }
                    } catch (err) {
                        _didIteratorError3 = true;
                        _iteratorError3 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion3 && _iterator3.return) {
                                _iterator3.return();
                            }
                        } finally {
                            if (_didIteratorError3) {
                                throw _iteratorError3;
                            }
                        }
                    }
                });
            }
        }
    }]);

    return FFInput;
}(FFComponent);

$(function () {
    $('.ff-input').each(function () {
        $(this).data('component', new FFInput(this));
    });
});

